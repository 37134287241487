import React from "react"
import { Link } from "gatsby"
import Layout from "@components/layout"
import SEO from "@components/seo"

import HeadingBanner from "@images/hero-bg5.jpeg"

const NotFoundPage = () => (
	<Layout>
		<SEO title="Not Found" />
		<div
			id="hero-banner"
			style={{
				backgroundImage: `url(${HeadingBanner})`,
				backgroundPosition: `bottom`,
			}}
		>
			<div className="hero-text">
				<div className="container">
					<div className="row justify-content-between align-items-center text-white text-center">
						<div className="col-12">
							<h1>Not Found</h1>
							<p>The page you are looking for does not exist.</p>
							<Link to="/" className="hero-cta">
								Back to Home
							</Link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
)

export default NotFoundPage
